import { _classes, numberWithCommas } from "@scripts/utilities/helpers";
import styles from "./IncentivesForm.scss";
import { Text, CTA, Modal, Wysiwyg } from "@scripts/widgets";
import {
  FormProvider,
  StandardInput,
  SelectInput,
  CheckboxGroup,
} from "@scripts/form";
import axios from "axios";
import { useState } from "react";

const cl = _classes(styles);

IncentivesForm.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  thankyouBlurb: PropTypes.string,
};

export default function IncentivesForm({
  title,
  description,
  className = "",
  thankyouBlurb,
}) {
  const [modalValue, setModalValue] = useState(null);
  const [results, setResults] = useState(null);

  const calculateIncentives = (values) => {
    const annualSavings = parseInt(values.employees) * 3000;
    const parsed = parseInt(values.lease_term);
    const termSavings = (parsed > 12 ? 12 : parsed) * annualSavings;
    return { annualSavings, termSavings };
  };

  const renderResults = () => {
    return (
      <div>
        <Text
          text="Thank you! Please find your projected REAP savings."
          variant="body1"
          component="p"
        />
        <div className={cl("result")}>
          <div className={cl("result__left")}>
            <Text
              text="Your annual projected REAP savings"
              variant="body1"
              component="p"
              className={cl("result_title")}
            />
            <Text
              text={`$${numberWithCommas(results.annualSavings)}`}
              variant="body1"
              component="p"
            />
          </div>
          <div className={cl("result__right")}>
            <Text
              text="Your full term projected REAP savings"
              variant="body1"
              component="p"
              className={cl("result_title")}
            />
            <Text
              text={`$${numberWithCommas(results.termSavings)}`}
              variant="body1"
              component="p"
            />
          </div>
        </div>
        <Wysiwyg html={thankyouBlurb} />
      </div>
    );
  };

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      _subject: "Inquiry from REAP calculator The Refinery at Domino",
    };

    return axios
      .post(`https://formspree.io/mqkyqaba`, payload)
      .then(() => {})
      .catch(() => this.setState({ posting: false }));
  };

  const onSuccess = async (resp, data) => {
    setResults(calculateIncentives(data));
  };

  const renderContent = () => {
    if (results) {
      return renderResults();
    }

    return renderForm();
  };

  const renderForm = () => (
    <div className={cl("form")}>
      <FormProvider onSubmit={onSubmit} onSuccess={onSuccess}>
        <div className={cl("row")}>
          <SelectInput
            name="eligible"
            label="Has your company conducted substantial business operations either outside New York City or below 96th street in Manhattan for at least 24 consecutive months before you plan on relocating to Brooklyn? (required)"
            rules={{ required: "Eligiblity is required" }}
            className={cl(["field", "long"])}
            options={[
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
              { label: "Maybe", value: "Maybe" },
            ]}
            onSelect={(val) => {
              if (!val?.match(/yes/i)) {
                setModalValue({
                  text: "This is a requirement to qualify for REAP.",
                  link: "https://www1.nyc.gov/site/finance/benefits/business-reap.page",
                });
              }
            }}
          />
        </div>

        <div className={cl("row")}>
          <StandardInput
            name="employees"
            label="Number of employees expected in your Williamsburg office (required)"
            rules={{ required: "Employee Count is required" }}
            className={cl("field")}
            type="number"
          />
        </div>

        <div className={cl("row")}>
          <StandardInput
            name="lease_term"
            label="Lease term expected in your Williamsburg office (required)"
            rules={{ required: "Lease Term is required" }}
            className={cl("field")}
            type="number"
          />
        </div>

        <div className={cl("row")}>
          <SelectInput
            name="use"
            label="Use (required)"
            rules={{ required: "Use is required" }}
            className={cl(["field", "long"])}
            options={[
              { label: "Office", value: "Office" },
              { label: "Industrial", value: "Industrial" },
              { label: "Retail", value: "Retail" },
              { label: "Hotel", value: "Hotel" },
            ]}
            onSelect={(val) => {
              if (val?.match(/hotel|retail/i)) {
                setModalValue({
                  text: "Most retail activities and hotel services do not qualify for REAP and CRT incentives.",
                  link: "https://www1.nyc.gov/site/finance/benefits/business-reap.page",
                });
              }
            }}
          />
        </div>

        <div className={cl("row")}>
          <CheckboxGroup
            name="interest"
            label="Are you interested in learning more about two trees' brooklyn properties?"
            rules={{ required: "Interest is required" }}
            options={[
              { label: "No", value: "No" },
              { label: "DUMBO Properties", value: "DUMBO Properties" },
              { label: "Ten Grand Street", value: "Ten Grand Street" },
            ]}
            className={cl("field")}
          />
        </div>

        <div className={cl("row")}>
          <StandardInput
            name="name"
            label="Name (Required)"
            rules={{ required: "Name is required" }}
            className={cl("field")}
          />
          <StandardInput
            name="company"
            label="Company (Optional)"
            className={cl("field")}
          />
        </div>
        <div className={cl("row")}>
          <StandardInput
            name="email"
            label="Email (Required)"
            rules={{ required: "Email is required" }}
            className={cl("field")}
          />
          <StandardInput
            name="phone"
            label="Phone (Optional)"
            className={cl("field")}
          />
        </div>
        <div className={cl("row")}>
          <CTA text="Calculate Savings" type="submit" variant="submit" />
        </div>
      </FormProvider>
    </div>
  );
  return (
    <div className={cl(["root", className])}>
      {!results && (
        <div className={cl("info")}>
          <Text
            text={title}
            variant="body1"
            component="h3"
            className={cl("title")}
          />
          <Text
            text={description}
            component="p"
            variant="body1"
            className={cl("description")}
          />
        </div>
      )}

      {renderContent()}

      <Modal
        open={!!modalValue}
        onClose={() => setModalValue(null)}
        modalClassname={cl("modal")}
      >
        {modalValue && (
          <div className={cl("modal_content")}>
            <Text text={modalValue.text} component="p" variant="body1" />
            <CTA
              text="Read More"
              link={modalValue.link}
              variant="primary-light"
              className={cl("modal_cta")}
              external
            />
          </div>
        )}
      </Modal>
    </div>
  );
}
