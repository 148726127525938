import { useEffect, useRef } from "react";
import Slick from "react-slick";
import CarouselArrow from "../partials/Arrow.js";
import { useViewportContext } from "../providers";
import { Text, Link, Image, Container } from "@scripts/widgets";
import dayjs from "dayjs";
// when using the carousel in your component:
// wrap the carousel in a container
// it will inherit the size of it's wrapper :)

Carousel.propTypes = {
  slides: PropTypes.array,
  settings: PropTypes.object,
  className: PropTypes.string,
  keyboardControl: PropTypes.bool,
  hasOverlay: PropTypes.bool,
  hasCaption: PropTypes.bool,
  arrowColor: PropTypes.string,
  arrows: PropTypes.bool,
  videoURL: PropTypes.string,
  isPress: PropTypes.bool,
};
Carousel.defaultProps = {
  settings: {
    arrows: true,
    dots: false,
    arrowColor: "white",
  },
};

function Carousel(props) {
  const {
    slides,
    className,
    settings,
    keyboardControl,
    hasOverlay,
    hasCaption,
    arrowColor,
    isPress,
    fromNestio,
  } = props;

  const slick = useRef();
  const { viewport } = useViewportContext();

  const renderCarousel = () => {
    return (
      <Slick ref={slick} {...allSettings}>
        {slides.map((slide, i) => {
          return (
            <div className={`slide-container`} key={i}>
              {fromNestio
                ? imageComponentForNestioSlides(slide)
                : imageComponentFor(slide)}
              {hasOverlay && !viewport.is("mobile") && (
                <div className="overlay">
                  <p>{slide.h3}</p>
                </div>
              )}
              {hasCaption && (
                <div className="caption">
                  <p>{slide.h2}</p>
                </div>
              )}
              {isPress && (
                <div className="carousel-press">
                  <Container extraPadding>
                    <Link path={slide.h3} className={"carousel-press-link"}>
                      <Text
                        text={slide.h2}
                        className="carousel-press-subtitle"
                        variant="h2"
                        component="h3"
                      />
                      <Text
                        text={slide.h1}
                        className="carousel-press-title"
                        variant="h1"
                        component="h2"
                      />
                      <Text
                        text={dayjs(slide.h4.replace(/-/g, "/")).format(
                          "MMM DD, YYYY"
                        )}
                        className="carousel-press-date"
                        variant="body1"
                        component="p"
                      />
                    </Link>
                  </Container>
                </div>
              )}
            </div>
          );
        })}
      </Slick>
    );
  };

  function renderArrow(direction) {
    return (
      <CarouselArrow
        className={`arrow ${direction === "left" ? "left" : "right"}`}
        color={arrowColor}
        direction={direction}
        onClick={() => {
          if (direction === "left") {
            slick.current.slickPrev();
          } else {
            slick.current.slickNext();
          }
        }}
      />
    );
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  const imageComponentFor = (slide) => {
    const imgProps = {
      align: slide.image1_anchor,
      src: slide.image1,
      alt: slide.image1_alt_text,
      className: isPress ? "press-image" : "",
    };
    return <Image {...imgProps} />;
  };

  const imageComponentForNestioSlides = (slide) => {
    const imgProps = {
      src: slide.original,
      align: "",
      alt: "",
      className: isPress ? "press-image" : "",
    };

    return <Image {...imgProps} />;
  };

  const handleKeyDown = (e) => {
    if (keyboardControl && slick) {
      if (
        !document.activeElement ||
        document.activeElement.tagName !== "INPUT"
      ) {
        if (e.key === "ArrowLeft" || e.key === "Left") {
          slick.slickPrev();
        } else if (e.key === "ArrowRight" || e.key === "Right") {
          slick.slickNext();
        }
      }
    }
  };
  if (slides.length === 1 && !slides[0].video_url) {
    return (
      <div
        className={`carousel-with-one-image ${className || ""}`}
        style={{ padding: 0 }}
      >
        {fromNestio
          ? imageComponentForNestioSlides(slides[0])
          : imageComponentFor(slides[0])}
      </div>
    );
  }
  let arrowButtons = [];
  // if (arrows) {
  arrowButtons = {
    nextArrow: renderArrow("right"),
    prevArrow: renderArrow("left"),
  };
  // }

  let allSettings = {
    ...settings,
    ...arrowButtons,
  };

  return <div className={`carousel ${className}`}>{renderCarousel()}</div>;
}

export default Carousel;
