import { _classes } from "@scripts/utilities/helpers";
import styles from "./ArrowCTA.scss";
import { directionArrow } from "@scripts/widgets/SVG";
import { Link, Text } from "@scripts/widgets";

const cl = _classes(styles);

ArrowCTA.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(["left", "right", "down", "up"]),
  link: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf(["link", "submit", "button"]),
  variant: PropTypes.oneOf(["cta", "submit", "swipe"]),
  onClick: PropTypes.func,
  download: PropTypes.boolean,
};

export default function ArrowCTA({
  className,
  path,
  text,
  type = "link",
  variant = "cta",
  direction = "right",
  onClick,
  download,
}) {
  const renderArrows = () => {
    return (
      <div className={cl(["arrow", direction])} type={type}>
        {variant == "swipe" && directionArrow}
        {directionArrow}
      </div>
    );
  };
  const content = () => (
    <>
      {text && <Text text={text} className={cl("text")} />}
      {renderArrows()}
    </>
  );

  if (type == "submit" || type == "button") {
    return (
      <button
        path={path}
        className={cl(["root", variant, className])}
        type={type}
        onClick={onClick}
      >
        {content()}
      </button>
    );
  }

  return (
    <Link
      path={path}
      className={cl(["root", variant, className, download && "download"])}
      target="_blank"
      onClick={onClick}
    >
      {content()}
    </Link>
  );
}
