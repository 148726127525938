import { _classes } from "@scripts/utilities/helpers";
import styles from "./ImageGrid.scss";
import { Image } from "@scripts/widgets";
import { useViewportContext } from "../../providers";
import { Carousel } from "../../widgets";

const cl = _classes(styles);

ImageGrid.propTypes = {
  slides: PropTypes.array,
};

export default function ImageGrid({ slides }) {
  const { viewport } = useViewportContext();

  const renderSlides = () => {
    if (viewport.is("mobile")) {
      return (
        <div className={cl("carousel")}>
          <Carousel slides={slides} />
        </div>
      );
    }

    return (
      <ul className={cl("list")}>
        {slides?.map((slide, index) => (
          <li key={index} className={cl("image")}>
            <Image src={slide.image1} alt={slide.image1_alt_text} />
          </li>
        ))}
      </ul>
    );
  };

  return <section className={cl("root")}>{renderSlides()}</section>;
}
