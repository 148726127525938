// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".arrow______CzFLI{height:50px;width:50px}.arrow______CzFLI:hover svg{stroke:#ffda00}.arrow______CzFLI.arrow__left___rmZf8{-webkit-transform:scale(-1) !important;transform:scale(-1) !important}.arrow______CzFLI.arrow__white___elAsX svg{-webkit-transition:all 250ms ease-in-out;transition:all 250ms ease-in-out;stroke:#fff;fill:#fff}.arrow______CzFLI.arrow__yellow___Qn17a svg{stroke:#ffda00;fill:#ffda00}.arrow______CzFLI svg{height:100%;width:100%}", "",{"version":3,"sources":["webpack://./src/styles/partials/arrow.scss","webpack://./src/styles/global/_colors.scss"],"names":[],"mappings":"AAEA,kBACE,WAAA,CACA,UAAA,CAGE,4BACE,cCFG,CDMP,sCACE,sCAAA,CAAA,8BAAA,CAGA,2CACE,wCAAA,CAAA,gCAAA,CACA,WClBE,CDmBF,SCnBE,CDuBJ,4CACE,cClBG,CDmBH,YCnBG,CDuBP,sBACE,WAAA,CACA,UAAA","sourcesContent":["@import \"@styles/global/parts\";\n\n._ {\n  height: 50px;\n  width: 50px;\n\n  &:hover {\n    svg {\n      stroke: $yellow;\n    }\n  }\n\n  &.left {\n    transform: scale(-1) !important;\n  }\n  &.white {\n    svg {\n      transition: all 250ms ease-in-out;\n      stroke: $white;\n      fill: $white;\n    }\n  }\n  &.yellow {\n    svg {\n      stroke: $yellow;\n      fill: $yellow;\n    }\n  }\n\n  svg {\n    height: 100%;\n    width: 100%;\n  }\n}\n\n@include _notAccessible {\n}\n","$white: #fff;\n$dark: #2b2b2b;\n$errorRed: #e01c1c;\n$blue: #4d90fe;\n$black: #000000;\n$beige: #e4e3e0;\n$yellow: #ffda00;\n\n$darkGrey: #343536;\n$darkerGrey: #2b2b2b;\n$offWhite: #e4e3e0;\n$lightGrey: #e5e5e5;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_": "arrow______CzFLI",
	"left": "arrow__left___rmZf8",
	"white": "arrow__white___elAsX",
	"yellow": "arrow__yellow___Qn17a"
};
export default ___CSS_LOADER_EXPORT___;
