import { _classes } from "@scripts/utilities/helpers";
import styles from "./OfficesViews.scss";
import { Container, Text, Carousel } from "@scripts/widgets";
const cl = _classes(styles);

OfficesViews.propTypes = {
  title: PropTypes.string,
  slides: PropTypes.array,
};

export default function OfficesViews({ title, slides = [] }) {
  return (
    <section className={cl("root")}>
      <Container>
        <Text component="h2" className={cl("title_wrapper")}>
          <Text
            text={title?.split(" ")[0]}
            variant="h2"
            className={cl(["text", "subtitle"])}
          />
          <br />
          <Text
            text={title?.split(" ")[1]}
            variant="h1"
            className={cl(["text", "title"])}
          />
        </Text>
      </Container>
      <div className={cl("carousel")}>
        <Carousel slides={slides} />
      </div>
    </section>
  );
}
